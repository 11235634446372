exports.components = {
  "component---src-components-cart-v-2-cart-cart-component-tsx": () => import("./../../../src/components/cart/v2/cart/cart.component.tsx" /* webpackChunkName: "component---src-components-cart-v-2-cart-cart-component-tsx" */),
  "component---src-components-cart-v-2-checkout-checkout-component-tsx": () => import("./../../../src/components/cart/v2/checkout/checkout.component.tsx" /* webpackChunkName: "component---src-components-cart-v-2-checkout-checkout-component-tsx" */),
  "component---src-components-events-event-page-tsx": () => import("./../../../src/components/events/event.page.tsx" /* webpackChunkName: "component---src-components-events-event-page-tsx" */),
  "component---src-components-page-tsx": () => import("./../../../src/components/Page.tsx" /* webpackChunkName: "component---src-components-page-tsx" */),
  "component---src-components-payment-payment-cancelled-component-tsx": () => import("./../../../src/components/payment/payment-cancelled.component.tsx" /* webpackChunkName: "component---src-components-payment-payment-cancelled-component-tsx" */),
  "component---src-components-stream-stream-page-tsx": () => import("./../../../src/components/stream/stream.page.tsx" /* webpackChunkName: "component---src-components-stream-stream-page-tsx" */),
  "component---src-components-venues-venue-page-tsx": () => import("./../../../src/components/venues/venue.page.tsx" /* webpackChunkName: "component---src-components-venues-venue-page-tsx" */),
  "component---src-components-virtual-card-badge-page-tsx": () => import("./../../../src/components/virtual-card/badge.page.tsx" /* webpackChunkName: "component---src-components-virtual-card-badge-page-tsx" */),
  "component---src-components-virtual-card-card-page-tsx": () => import("./../../../src/components/virtual-card/card.page.tsx" /* webpackChunkName: "component---src-components-virtual-card-card-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-code-tsx": () => import("./../../../src/pages/code.tsx" /* webpackChunkName: "component---src-pages-code-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-fi-tsx": () => import("./../../../src/pages/fi.tsx" /* webpackChunkName: "component---src-pages-fi-tsx" */),
  "component---src-pages-order-confirmation-tsx": () => import("./../../../src/pages/order-confirmation.tsx" /* webpackChunkName: "component---src-pages-order-confirmation-tsx" */),
  "component---src-pages-pay-order-tsx": () => import("./../../../src/pages/pay-order.tsx" /* webpackChunkName: "component---src-pages-pay-order-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

